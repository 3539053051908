// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side-bar-wrap {
  width: 100%;
}

.fixed {
  position: fixed;
}

.corner-position {
  background-color: transparent;
  z-index: 9;
  margin-left: 2rem;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.side-bar-nav {
  background-color: #FAF9F6;
  box-shadow: 1px 1px 7px rgba(0,0,0,0.15);
  width: 280px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  transition: 350ms;
  z-index: 9;
}

.navbar-menu {
  height:100vh;
  box-shadow: 1px 1px 7px rgba(0,0,0,0.15);
  transition: all 0.5s ease;
}

.navbar__list {
  list-style-type: none;
  
}
.navbar__li {
  display: inline-block;
  padding:0px 0px 0px 12px;
  position: relative;
  top:2.5px;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 38px;
  color: black;
  font-family: "Lato";
  font-weight: 400;
}

.navbar__li-box:first-child {
  margin-top:23px;
  
}

.navbar__li-box {
  height: 43px;
  margin-top:15px;
}
`, "",{"version":3,"sources":["webpack://./src/App/pages/Header/styles.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,6BAA6B;EAC7B,UAAU;EACV,iBAAiB;EACjB,eAAe;EACf,YAAY;EACZ,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,wCAAwC;EACxC,YAAY;EACZ,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,eAAe;EACf,MAAM;EACN,iBAAiB;EACjB,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,wCAAwC;EACxC,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;;AAEvB;AACA;EACE,qBAAqB;EACrB,wBAAwB;EACxB,kBAAkB;EAClB,SAAS;EACT,eAAe;EACf,mBAAmB;EACnB,iBAAiB;EACjB,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,eAAe;;AAEjB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".side-bar-wrap {\n  width: 100%;\n}\n\n.fixed {\n  position: fixed;\n}\n\n.corner-position {\n  background-color: transparent;\n  z-index: 9;\n  margin-left: 2rem;\n  font-size: 2rem;\n  height: 80px;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n}\n\n.side-bar-nav {\n  background-color: #FAF9F6;\n  box-shadow: 1px 1px 7px rgba(0,0,0,0.15);\n  width: 280px;\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  position: fixed;\n  top: 0;\n  transition: 350ms;\n  z-index: 9;\n}\n\n.navbar-menu {\n  height:100vh;\n  box-shadow: 1px 1px 7px rgba(0,0,0,0.15);\n  transition: all 0.5s ease;\n}\n\n.navbar__list {\n  list-style-type: none;\n  \n}\n.navbar__li {\n  display: inline-block;\n  padding:0px 0px 0px 12px;\n  position: relative;\n  top:2.5px;\n  font-size: 16px;\n  letter-spacing: 0px;\n  line-height: 38px;\n  color: black;\n  font-family: \"Lato\";\n  font-weight: 400;\n}\n\n.navbar__li-box:first-child {\n  margin-top:23px;\n  \n}\n\n.navbar__li-box {\n  height: 43px;\n  margin-top:15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
