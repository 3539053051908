import React from 'react';
import { useNavigate } from "react-router-dom";
import { Button } from '@mui/material';

import './styles.css';

function RsvpForm() {
  var guestName;
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevents default form submission behavior
    event.target.reset();
    const route = '/rsvp?guest=' + guestName;
    navigate(route);
  }

  const handleInputChange = (event) => {
    guestName = event.target.value;
  }

  return (
    <>
    <form class="rsvp-form" onSubmit={handleSubmit}>
      <div>
        <label for="name"><p>Enter your name, to find your invitation</p></label>
        <div class="input-container">
          <input  type="text" class="form-control" id="name"
                  aria-describedby="nameHelp" onChange={handleInputChange}  placeholder="Name" required/>
        </div>
        <br />
      </div>
      <div class="input-container">
        <Button type="submit" variant="outlined">
          Submit
        </Button>
      </div>
    </form>
    </>);
}

export default function Rsvp( { isSection }) {
  if (!isSection) {
    return (<><RsvpForm /></>)
  }

  return (
  <>
    <hr class="section-divider"/>
    <div id="rsvp" className="rsvp" class="section">
      <h1 class="section-title">Rsvp</h1>
      <RsvpForm />
    </div>
  </>
  );
}
