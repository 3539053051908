import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home/Home';
import Header from './pages/Header/Header';
import RsvpDetail from './pages/Rsvp/RsvpDetail';
import List from './pages/List';
import Confirmation from './pages/Confirmation/Confirmation';

class App extends Component {
  render() {
    const App = () => (
      <>
        <Header className="header" />
        <Routes>
          <Route exact path='/' element={<Home/>}/>
          <Route path='/home' element={<Home/>}/>
          <Route path='/rsvp' element={<RsvpDetail/>} exact/>
          <Route path='/confirmed' element={<Confirmation/>} exact/>
        </Routes>
      </>
    )
    return (
        <App/>
    );
  }
}

export default App;
