// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../Header/styles.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flex-container  {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.card {
	padding: 15px;
	margin-right: 20px;
	border: none;
	background-color: #FAF9F6;
}


.event-title {
	font-size: 2rem;
	margin-bottom: 20px;
	text-align: center;
}

.event-details, .event-list {
	font-size: 1rem;
}

.detail-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

p {
	margin: 10px;
}

.directions {
	width: 30%;
	margin-left: 35%;
	margin-right: 35%;
	margin-top: 40px;
}
.directions-icon {
	color: white;
	margin: 10px;
}`, "",{"version":3,"sources":["webpack://./src/App/pages/Travel/styles.css"],"names":[],"mappings":"AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,uBAAuB;AACxB;;AAEA;CACC,aAAa;CACb,kBAAkB;CAClB,YAAY;CACZ,yBAAyB;AAC1B;;;AAGA;CACC,eAAe;CACf,mBAAmB;CACnB,kBAAkB;AACnB;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,uBAAuB;AACxB;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,UAAU;CACV,gBAAgB;CAChB,iBAAiB;CACjB,gBAAgB;AACjB;AACA;CACC,YAAY;CACZ,YAAY;AACb","sourcesContent":["@import '../Header/styles.css';\n\n.flex-container  {\n\tdisplay: flex;\n\tflex-direction: row;\n\tjustify-content: center;\n}\n\n.card {\n\tpadding: 15px;\n\tmargin-right: 20px;\n\tborder: none;\n\tbackground-color: #FAF9F6;\n}\n\n\n.event-title {\n\tfont-size: 2rem;\n\tmargin-bottom: 20px;\n\ttext-align: center;\n}\n\n.event-details, .event-list {\n\tfont-size: 1rem;\n}\n\n.detail-container {\n\tdisplay: flex;\n\tflex-direction: row;\n\tjustify-content: center;\n}\n\np {\n\tmargin: 10px;\n}\n\n.directions {\n\twidth: 30%;\n\tmargin-left: 35%;\n\tmargin-right: 35%;\n\tmargin-top: 40px;\n}\n.directions-icon {\n\tcolor: white;\n\tmargin: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
