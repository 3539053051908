import React, { Component } from 'react';

import './styles.css';

const events = [
  {name: 'Signing of the Ketubah', description: 'The Ketubah is a Jewish wedding contract.'},
  {name: 'Badeken', description: 'this is it'},
  {name: 'Chuppah', description: 'this is it'},
  {name: 'Blessings of Betrothal (Kiddush)', description: 'this is it'},
  {name: 'Giving of the Ring', description: 'this is it'},
  {name: 'Reading of the Ketubah', description: 'this is it'},
  {name: 'The Seven Blessings', description: 'this is it'},
  {name: 'The Breaking of the Glass', description: 'this is it'}
  ];

function Event ( { event }) {
  return (
    <>
      <div class="event-container">
        <p class="name">{event.name}</p>
        <p class="description">{event.description}</p>
      </div>
    </>);
}

function CeremonyView () {
  const eventViews = events.map(e => <><Event event={e} ></Event></>);

  return (
    <>
      <hr class="section-divider"/>
      <div id="ceremony" className="ceremony section">
        <h1 class="section-title">Jewish Ceremony</h1>
        <p>For those unfamiliar with Jewish ceremonies, we provided an order of events and their significance so you can comfortably partake in all the festivities!</p>
        {eventViews}
      </div>
    </>
    );
}
class Ceremony extends Component {

  render() {
    return (
    <CeremonyView />
    );
  }
}
export default Ceremony;