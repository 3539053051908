import React, { useState, useRef, useEffect } from 'react';
import { FaBars, FaTimes, FaHouseUser, FaQuestion, FaBus, FaStarOfDavid, FaGifts, FaAddressCard, FaRegCalendar, FaHotel } from "react-icons/fa";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Nav from 'react-bootstrap/Nav';

import './styles.css';
 
const NavIcon = styled(Link)`
    margin-left: 2rem;
    font-size: 2rem;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;
 
const SidebarNav = styled.nav`
    left: ${({ sideBarVisible }) => (sideBarVisible ? "0" : "-100%")};
`;

function useOutsideClickAlerter(ref, callbackFunction) {
  /**
   * Initiates component that calls the callback function when a click is
   * registered outside of the referenced component.
   */
  useEffect(() => {
    // console.log("So were'hereeeee")
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callbackFunction();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

function CustomNav() {
 const [sideBarVisible, setSideBarVisible] = useState(false);
 const wrapperRef = useRef(null);
 const urlParams = new URLSearchParams(window.location.search);
 const isRsvpOn = urlParams.has("rsvp")
   ? urlParams.get("rsvp") == "on"
   : false;

  const toggleSidebar = () => {
    setSideBarVisible(!sideBarVisible);
  };

  // Clear suggested results when click registered outside of component.
  useOutsideClickAlerter(wrapperRef, () => {
    setSideBarVisible(false);
  });

 return (
    <>
      <div className="fixed corner-position">
        <FaBars style={{color: 'black'}}
          onClick={toggleSidebar}/>
      </div>
      <SidebarNav sideBarVisible={sideBarVisible} className="side-bar-nav" ref={wrapperRef}>
        <div className="side-bar-wrap">
          <div className="corner-position">
            <FaTimes style={{color: 'black'}}
              onClick={toggleSidebar}/>
          </div>
        <ul className="navbar__list">
          <Nav.Link className="navbar__li-box" href={"#home"} key={"home"}>
            <FaHouseUser style={{color: 'black'}}/>
            <li className="navbar__li" style={{ display:  "inline-block" }}><p>Home</p></li>
          </Nav.Link>
          <Nav.Link className="navbar__li-box" href={"#schedule"} key={"schedule"}>
            <FaRegCalendar style={{color: 'black'}}/>
            <li className="navbar__li" style={{ display:  "inline-block" }}><p>Schedule</p></li>
          </Nav.Link>
          <Nav.Link className="navbar__li-box" href={"#travel"} key={"accommodations"}>
            <FaHotel style={{color: 'black'}}/>
            <li className="navbar__li" style={{ display:  "inline-block" }}><p>Accommodations</p></li>
          </Nav.Link>
          <Nav.Link className="navbar__li-box" href={"#transportation"} key={"transportation"}>
            <FaBus style={{color: 'black'}}/>
            <li className="navbar__li" style={{ display:  "inline-block" }}><p>Transportation</p></li>
          </Nav.Link>
          { isRsvpOn ?
          <Nav.Link className="navbar__li-box" href={"#rsvp"} key={"rsvp"}>
            <FaAddressCard style={{color: 'black'}}/>
            <li className="navbar__li" style={{ display:  "inline-block" }}><p>RSVP</p></li>
          </Nav.Link> : <></>}
          <Nav.Link className="navbar__li-box" href={"#registry"} key={"registry"}>
            <FaGifts style={{color: 'black'}}/>
            <li className="navbar__li" style={{ display:  "inline-block" }}><p>Registry</p></li>
          </Nav.Link>
          <Nav.Link className="navbar__li-box" href={"#faq"} key={"faq"}>
            <FaQuestion style={{color: 'black'}}/>
            <li className="navbar__li" style={{ display:  "inline-block" }}><p>FAQ</p></li>
          </Nav.Link>
          {/* <Nav.Link className="navbar__li-box" href={"#ceremony"} key={"ceremony"}>
            <FaStarOfDavid style={{color: 'black'}}/>
            <li className="navbar__li" style={{ display:  "inline-block" }}><p>Jewish Ceremony</p></li>
          </Nav.Link> */}
        </ul>
        </div>
      </SidebarNav>
    </>
  );
}

export default function Header() {
   return (<CustomNav />);
}