
import React, { useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie'

import "./styles.css";

export default function PasswordPromptDialog({ setAuth }) {
  const [cookies, setCookie] = useCookies(['authenticated']);
  const [password, setPassword] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);

  const passwordInput = document.getElementById("websitePasswordInput");

  let needsAuthentication = true;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {data: response} = await axios.get('https://kitay-mechali-pipeline-kitay-mechali-service-vu3uk6fctq-ue.a.run.app/api/authenticate/'+password);
    let isValid = response.auth == 'success';
    if (isValid) {
      setCookie('authenticated', isValid);
      updateNeedsAuthentication();
      setIsInvalid(false);
    } else {
      setIsInvalid(true);
    }
  };

  function updateNeedsAuthentication() {
    needsAuthentication = cookies == null || !cookies.authenticated;
    setAuth(!needsAuthentication);
  }

  updateNeedsAuthentication();

  if (!needsAuthentication) {
    return (<></>);
  } else {
    return (
    <>
      <div className="overlay__background">
        <div className="cc">
        <form onSubmit={handleSubmit}>
          <div class="form-group">
            <input type="password"
              required
              class="form-control"
              id="websitePasswordInput" 
              value={password}
              className={`form-control ${isInvalid ? 'invalid' : ''}`}
              onInvalid={() => "This is invalid."}
              onChange={(e) => setPassword(e.target.value)}
              aria-describedby="passwordHelp"
              placeholder="Password" />
              <button class="btn shadow-none">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
                </svg>
              </button>
            </div>
        </form>
      { isInvalid ? <p className="bad-password">Invalid Password</p> : <></>}
      </div>
       </div>
    </>
    );
  }
}