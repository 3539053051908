// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../Header/styles.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-link {
	width: 100%;
}

img {
	height: 8h;
	width: 20vh;
}`, "",{"version":3,"sources":["webpack://./src/App/pages/Registry/styles.css"],"names":[],"mappings":"AAEA;CACC,WAAW;AACZ;;AAEA;CACC,UAAU;CACV,WAAW;AACZ","sourcesContent":["@import '../Header/styles.css';\n\n.logo-link {\n\twidth: 100%;\n}\n\nimg {\n\theight: 8h;\n\twidth: 20vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
