// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../Header/styles.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Parisienne);"]);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overlay__background {
  position: relative;
  background-color: rgba(250, 249, 246, 1);
  width: 100vw;
  height: 100vh;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
}

.cc {
  display: flex;
  flex-direction: column;
}

.form-group {
  position: relative;
  height: 5vh;
  width : 300px;
}

.form-group input{
    width: 100%;
    height: 55px;
}

.form-group button {
  position: absolute;
  right: 10px;
  top: 0px;
  height: 55px;
}

.form-group svg {
  position: absolute;
  top: 0px;
  right: 5px;
  height: 100%;  
  width: 35px;
  color: grey;
}

.form-group svg:hover {
  color: black;
}

.invalid {
  border-color: red;
}

.bad-password {
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/App/pages/Password/styles.css"],"names":[],"mappings":"AAGA;EACE,kBAAkB;EAClB,wCAAwC;EACxC,YAAY;EACZ,aAAa;EACb,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,aAAa;AACf;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,QAAQ;EACR,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,YAAY;EACZ,WAAW;EACX,WAAW;AACb;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,UAAU;AACZ","sourcesContent":["@import url('https://fonts.googleapis.com/css?family=Parisienne');\n@import '../Header/styles.css';\n\n.overlay__background {\n  position: relative;\n  background-color: rgba(250, 249, 246, 1);\n  width: 100vw;\n  height: 100vh;\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 11;\n}\n\n.cc {\n  display: flex;\n  flex-direction: column;\n}\n\n.form-group {\n  position: relative;\n  height: 5vh;\n  width : 300px;\n}\n\n.form-group input{\n    width: 100%;\n    height: 55px;\n}\n\n.form-group button {\n  position: absolute;\n  right: 10px;\n  top: 0px;\n  height: 55px;\n}\n\n.form-group svg {\n  position: absolute;\n  top: 0px;\n  right: 5px;\n  height: 100%;  \n  width: 35px;\n  color: grey;\n}\n\n.form-group svg:hover {\n  color: black;\n}\n\n.invalid {\n  border-color: red;\n}\n\n.bad-password {\n  color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
