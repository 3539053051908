// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../Header/styles.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 480px) {
	.welcome-details  {
		font-size: 1rem;
	}
}

@media (min-width: 480px) {
	.welcome-details  {
		font-size: 2rem;
	}
}`, "",{"version":3,"sources":["webpack://./src/App/pages/Welcome/styles.css"],"names":[],"mappings":"AAEA;CACC;EACC,eAAe;CAChB;AACD;;AAEA;CACC;EACC,eAAe;CAChB;AACD","sourcesContent":["@import '../Header/styles.css';\n\n@media (max-width: 480px) {\n\t.welcome-details  {\n\t\tfont-size: 1rem;\n\t}\n}\n\n@media (min-width: 480px) {\n\t.welcome-details  {\n\t\tfont-size: 2rem;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
