// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../Header/styles.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rsvp-form {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.form-control {
	margin-top: 20px;
	width: 25vh;
}

.submit-btn {
	width: 10vh;
}


.input-container, .btn-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.rsvp-info {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
}

.demo-simple-select {
	width: 50px;
}

.typo {
	width: 50%;
}

.frag {
	display: flex;
	flex-direction: column;
	align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/App/pages/Rsvp/styles.css"],"names":[],"mappings":"AAEA;CACC,aAAa;CACb,sBAAsB;CACtB,uBAAuB;AACxB;;AAEA;CACC,gBAAgB;CAChB,WAAW;AACZ;;AAEA;CACC,WAAW;AACZ;;;AAGA;CACC,aAAa;CACb,mBAAmB;CACnB,uBAAuB;AACxB;;AAEA;CACC,aAAa;CACb,sBAAsB;CACtB,mBAAmB;CACnB,YAAY;AACb;;AAEA;CACC,WAAW;AACZ;;AAEA;CACC,UAAU;AACX;;AAEA;CACC,aAAa;CACb,sBAAsB;CACtB,mBAAmB;AACpB","sourcesContent":["@import '../Header/styles.css';\n\n.rsvp-form {\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: center;\n}\n\n.form-control {\n\tmargin-top: 20px;\n\twidth: 25vh;\n}\n\n.submit-btn {\n\twidth: 10vh;\n}\n\n\n.input-container, .btn-container {\n\tdisplay: flex;\n\tflex-direction: row;\n\tjustify-content: center;\n}\n\n.rsvp-info {\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\theight: 100%;\n}\n\n.demo-simple-select {\n\twidth: 50px;\n}\n\n.typo {\n\twidth: 50%;\n}\n\n.frag {\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
