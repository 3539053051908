import React, { Component } from 'react';
import { FaGifts } from "react-icons/fa";

import './styles.css';

function LogoLink({ link, img }) {
  return (
    <>
      <a class="logo-link" href={link} target="_blank">
        <img src={img}  />
      </a>
    </>
    );
}

class Registry extends Component {
  render() {
    return (
    <>
      <hr class="section-divider"/>
      <div id="registry" className="registry" class="section">
        <h1 class="section-title">Our registry</h1>
        <p>Your presence is the greatest gift, and we appreciate the effort to come celebrate with us in Paris. If you still wish to get us a gift, see our registry below!</p><br></br><br></br>
        <LogoLink link="https://registry.theknot.com/shira-kitay-gabriel-mechali-august-2025-france/66961433" img="theKnot.png" />
      </div>
    </>
    );
  }
}
export default Registry;