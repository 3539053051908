import React, { useState } from 'react';
import PasswordPromptDialog from '../Password/PasswordPromptDialog';

export default function Confirmation() {
    const [isAuth, setAuthed] = React.useState(false);
  
    if (!isAuth) {
      return (
        <>
          <PasswordPromptDialog setAuth={setAuthed} />
        </>);
    } else {
      return (
        <>
          <div id="rsvp" className="rsvp-info section">
            <h1 className="section-title">Rsvp Confirmation</h1>
            <p>Thank you for submitting your response.</p>
          </div>
        </>);
    }
  }