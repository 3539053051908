// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../Header/styles.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prompt {
	text-decoration: underline;
	text-align: left;
}

.question-container {
	padding-left: 50px;
	text-align: left;
	margin-bottom: 40px;
}


@media (max-width: 480px) {
	.prompt, .answer {
		font-size: 1rem;
	}
}`, "",{"version":3,"sources":["webpack://./src/App/pages/Faq/styles.css"],"names":[],"mappings":"AAEA;CACC,0BAA0B;CAC1B,gBAAgB;AACjB;;AAEA;CACC,kBAAkB;CAClB,gBAAgB;CAChB,mBAAmB;AACpB;;;AAGA;CACC;EACC,eAAe;CAChB;AACD","sourcesContent":["@import '../Header/styles.css';\n\n.prompt {\n\ttext-decoration: underline;\n\ttext-align: left;\n}\n\n.question-container {\n\tpadding-left: 50px;\n\ttext-align: left;\n\tmargin-bottom: 40px;\n}\n\n\n@media (max-width: 480px) {\n\t.prompt, .answer {\n\t\tfont-size: 1rem;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
