// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../Header/styles.css";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./../../../../public/viaferrata.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./../../../../public/engagementDay.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./../../../../public/rivver.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./../../../../public/mirror.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./../../../../public/color_laugh.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.via-ferrata {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

@media (max-width: 480px) {
	.photo-div {
        width: 70%;
        margin: 10px 15%;
        height: 200px;
        justify-content: center;
        align-items: center;
        background-repeat: no-repeat;
    }
}

@media (min-width: 480px) {
	.photo-div {
        width: 40%;
        margin: 10px 30%;
        height: 500px;
        justify-content: center;
        align-items: center;
        background-repeat: no-repeat;
    }
}



.engagementday {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

.rivver {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}

.mirror {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}

.laugh {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
}`, "",{"version":3,"sources":["webpack://./src/App/pages/PhotoSection/styles.css"],"names":[],"mappings":"AAEA;IACI,yDAA4D;AAChE;;AAEA;CACC;QACO,UAAU;QACV,gBAAgB;QAChB,aAAa;QACb,uBAAuB;QACvB,mBAAmB;QACnB,4BAA4B;IAChC;AACJ;;AAEA;CACC;QACO,UAAU;QACV,gBAAgB;QAChB,aAAa;QACb,uBAAuB;QACvB,mBAAmB;QACnB,4BAA4B;IAChC;AACJ;;;;AAIA;IACI,yDAA+D;AACnE;;AAEA;IACI,yDAAwD;AAC5D;;AAEA;IACI,yDAAwD;AAC5D;;AAEA;IACI,yDAA6D;AACjE","sourcesContent":["@import '../Header/styles.css';\n\n.via-ferrata {\n    background-image: url('./../../../../public/viaferrata.jpg');\n}\n\n@media (max-width: 480px) {\n\t.photo-div {\n        width: 70%;\n        margin: 10px 15%;\n        height: 200px;\n        justify-content: center;\n        align-items: center;\n        background-repeat: no-repeat;\n    }\n}\n\n@media (min-width: 480px) {\n\t.photo-div {\n        width: 40%;\n        margin: 10px 30%;\n        height: 500px;\n        justify-content: center;\n        align-items: center;\n        background-repeat: no-repeat;\n    }\n}\n\n\n\n.engagementday {\n    background-image: url('./../../../../public/engagementDay.jpg');\n}\n\n.rivver {\n    background-image: url('./../../../../public/rivver.jpg');\n}\n\n.mirror {\n    background-image: url('./../../../../public/mirror.jpg');\n}\n\n.laugh {\n    background-image: url('./../../../../public/color_laugh.jpg');\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
